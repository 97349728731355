<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.color"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" >
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item
                            :label="$t('message.name')"
                            class="label_mini"
                            prop="name"
                        >
                            <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                            ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('message.color')" prop="color" class="label_mini">
                            <div class="color-picker-wrap d-flex">
                                <el-color-picker
                                    v-model="form.color"
                                    @active-change="changeColor"
                                ></el-color-picker>
                                <div class="picker-box ml-4 rounded w-100 p-1">
                                    <div
                                        class="h-100 rounded-sm"
                                        :style="{ background: form.color }"
                                    ></div>
                                </div>
                            </div>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "color",
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "colors/rules",
            model: "colors/model",
            columns: "colors/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "colors/update",
            show: "colors/show",
        }),
        changeColor(val){
          this.form.color = val;
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
